








































import { CommuniqueNotification } from 'vue-communique'
import { computed, defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'BaseNotification',

  inheritAttrs: false,

  props: {
    notification: {
      type: Object as PropType<CommuniqueNotification>,
      required: true,
    },
  },

  setup(props) {
    const iconTone = computed(() => props.notification.$attrs?.tone)

    return {
      iconTone,
    }
  },
})
